import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Title } from '../components/Title'
import { Text } from '../components/Text'
import { ImageSlider } from '../components/ImageSlider'
import { FormAndMap } from '../components/FormAndMap'
import { Styled } from '../styles/clinica.styles'
import ArrowIcon from '../assets/images/arrow.svg'
import { Link, graphql } from 'gatsby'

const TratamentosDentariosPage = ({ data: { clinicaJson: content }, location }) => {
  return (
    <Layout location={location}>
      <SEO title="A nossa Clínica" description={content.seoDescription} />
      <Styled.Container>
        <section
          className="intro"
          style={{ backgroundImage: `url(${content.backgroundImg})` }}
        >
          <div className="content">
            <Title text={content.mainTitle} />
            <Text text={content.mainText} />
          </div>
          <div
            className="image"
            style={{ backgroundImage: `url(${content.mainImg})` }}
          ></div>
          <div className="arrow bg-gold">
            <Link to="#offices">
              <ArrowIcon />
            </Link>
          </div>
        </section>

        <div
          className="separator-image"
          style={{ backgroundImage: `url(${content.separatorImg})` }}
        />

        <section className="offices bg-gold" id="offices">
          <Title text={content.officesTitle} />
          <Text text={content.officesText} />

          <div
            className="images-container"
            style={{ backgroundImage: `url(${content.backgroundImg})` }}
          >
            <ImageSlider images={content.officesSlider} />

            <div className="sterilization-room">
              <section className='separator'>
                <div className='container-hr'>
                  <hr />
                </div>
                <div>
                  <Title text={content.sterilizationRoomTitle} className="gold" />
                </div>
                <div className='container-hr'>
                  <hr />
                </div>
              </section>

              <img
                src={content.sterilizationRoomImg}
                alt={content.sterilizationRoomTitle}
              />
            </div>
          </div>
        </section>

        <FormAndMap withContactInfo white />
      </Styled.Container>
    </Layout>
  )
}

export default TratamentosDentariosPage

export const pageQuery = graphql`
  query {
    clinicaJson {
      seoDescription
      mainTitle
      mainText
      mainImg
      backgroundImg
      separatorImg
      officesTitle
      officesText
      officesSlider
      sterilizationRoomTitle
      sterilizationRoomImg
    }
    miscJson {
      alternAddress
      phone1
      phone2
    }
  }
`
