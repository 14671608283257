import React, { useState } from 'react'
import styled from 'styled-components'

export const ImageSlider = ({ images }) => {
  const [activeSlide, setActiveSlide] = useState(1)

  return (
    <StyledSlider className="image-slider">
      {activeSlide > 1 && (
        <span
          className="prev blue"
          onClick={() => setActiveSlide(activeSlide - 1)}
        >
          {'<'}
        </span>
      )}

      <img src={images[activeSlide - 1]} alt="Slider image" />

      {activeSlide < images.length && (
        <span
          className="next blue"
          onClick={() => setActiveSlide(activeSlide + 1)}
        >
          {'>'}
        </span>
      )}
    </StyledSlider>
  )
}

const StyledSlider = styled.div`
  position: relative;
  width: 75%;
  margin: 0 auto;

  img {
    max-width: 100%;
  }

  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 8rem;
    cursor: pointer;

    &.prev {
      left: -4rem;
    }
    &.next {
      right: -4rem;
    }
  }
`
