import React from 'react'
import MarkerIcon from '../assets/images/marker.svg'
import PhoneIcon from '../assets/images/phone.svg'
import { Form } from './Form'
import { Title } from './Title'
import { Text } from './Text'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import { handlePhoneClick } from '../utils/handleClick'

export const FormAndMap = ({ withContactInfo, white}) => {

  return (
    <StaticQuery
      query={graphql`
        query {
          miscJson {
            alternAddress
            phone1
            phone2
            contactTitle
            form {
              title
              subTitle
            }
          }
        }
      `}
      render={({ miscJson: content }) => (
        <StyledRow white={white}>
          {withContactInfo ? (
            <div className="contact">
              <Title text={content.contactTitle} />
              <div className="info">
                <div>
                  <MarkerIcon />
                  <p
                    className="blue"
                    dangerouslySetInnerHTML={{ __html: content.alternAddress }}
                  />
                </div>
                <div >
                  <PhoneIcon />
                  <p className="blue" style={{ display: "grid"}}>
                    <a onClick={() => handlePhoneClick(`tel: ${content.phone1.replace(/\s/g, '')}`, 'Móvel')}
                    >
                      {content.phone1}
                    </a> <small style={{fontStyle: "italic"}}> «Chamada para a rede móvel nacional» </small>{' '}
                    &#8226;{' '}
                    <a onClick={() => handlePhoneClick(`tel: ${content.phone2.replace(/\s/g, '')}`, 'Fixo')}
                    >
                      {content.phone2}
                    </a>
                    <small style={{fontStyle: "italic"}}> «Chamada para a rede fixa nacional» </small>
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="form" >
              <Title text={content.form.title} />
              <Text text={content.form.subTitle} />
              <Form  />
            </div>
          )}
          <div className="map">
            <iframe
              src="https://snazzymaps.com/embed/356260"
              className="iframe"
            ></iframe>
          </div>
        </StyledRow>
      )}
    />
  )
}

const StyledRow = styled.div`

  display: flex;
  background: ${(props) =>
    props.white ? props.theme.white : props.theme.gold};

  > div {
    width: 50%;
  }

  .contact {
    padding-bottom: ${({ theme }) => theme.spacingM};
    text-align: center;

    .title {
      color: ${({ theme }) => theme.gold};
      margin-top: ${({ theme }) => theme.spacingM};
    }

    svg {
      width: 3rem;
      path {
        fill: ${({ theme }) => theme.blue};
      }
    }

    .info > div {
      margin-top: ${({ theme }) => theme.spacingM};
    }

    p {
      font-size: 2rem;
      line-height: 3.5rem;

      a {
        margin: 0 ${({ theme }) => theme.spacingXXS};
      }
    }
  }

  .form {
    padding: ${({ theme }) => theme.spacingL};

    .text {
      margin-top: ${({ theme }) => theme.spacingS};
    }
  
    input:checked ~ span {
      background:${(props) =>
        props.white ? '#053d7f' : '#ffffff'} ;
    }
  }

  .map {
    display: flex;

    iframe {
      border: none;
      width: 100%;
      min-height: 50rem;
    }
    
  }

    
  @media screen and (max-width: 1000px) {
    flex-direction: column;

    > div {
      width: 100%;
    }
  }

  @media screen and (max-width: 500px) {
    .form {
      padding: ${({ theme }) => `${theme.spacingL} ${theme.spacingS}`};
    }
  }
`
