import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  padding-top: 12rem;

  .intro {
    display: flex;
    align-items: center;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;

    .content {
      padding: 0 ${({ theme }) => theme.spacingM};
      .title,
      .text {
        color: ${({ theme }) => theme.blue};
      }

      .title {
        font-size: 7rem;
        line-height: 7rem;
        max-width: 75rem;
      }

      .text {
        margin-top: ${({ theme }) => theme.spacingM};
        max-width: 50rem;
      }
    }

    .image {
      min-width: 45vw;
      height: calc(100vh - 12rem);
      background-position: center;
      background-size: cover;
      margin-left: auto;
      transform: scale(1.02);
      transform-origin: top;
      z-index: 11;
      border-bottom-left-radius: 50% 45rem;
      border-bottom-right-radius: 50% 45rem;
    }

    .arrow {
      position: absolute;
      bottom: -3.5rem;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 4.9rem;
      height: 9rem;
      padding-top: ${({ theme }) => theme.spacingXXS};
      display: flex;
      justify-content: center;
      border-radius: 25px;
      cursor: pointer;

      svg {
        width: 2.5rem;
        height: auto;
      }
    }
  }

  .separator{
    padding-left: ${({ theme }) => `${theme.spacingL}`};
    padding-right: ${({ theme }) => `${theme.spacingL}`};
    padding-bottom: ${({ theme }) => `${theme.spacingM}`};
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    .title{
      color: ${({ theme }) => `${theme.gold}`};
      font-family: ${({ theme }) => `${theme.fontFamily}`};
      text-align: center;
    }
    .container-hr{
      display: flex;
      align-items: center;
      hr{
        height: 1px;
        width: 100%;
        border: none;
        background-color: ${({ theme }) => `${theme.gold}`};
      }
    }
    .container-hr-blue{
      display: flex;
      align-items: center;
      hr{
        height: 1px;
        width: 100%;
        border: none;
        background-color: ${({ theme }) => `${theme.blue}`};
      }
    }
  }

  .separator-image {
    width: 100%;
    height: 50vh;
    background-position: center;
    background-size: cover;
  }

  .offices {
    padding: ${({ theme }) => `${theme.spacingL} 0 0`};
    text-align: center;

    .text {
      margin: ${({ theme }) => theme.spacingS} auto 0 auto;
      max-width: 80rem;
    }

    .images-container {
      margin-top: ${({ theme }) => theme.spacingXL};
      padding-bottom: ${({ theme }) => theme.spacingXL};
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      border-top-left-radius: 25rem;
      border-top-right-radius: 25rem;

      .image-slider {
        transform: translateY(-${({ theme }) => theme.spacingM});
      }
    }

    .sterilization-room {
      margin: ${({ theme }) => `${theme.spacingM} 0 0 0`};

      .title {
        position: relative;
        font-size: 4rem;
        width: max-content;
        margin: 0 auto;
        padding: ${({ theme }) => `${theme.spacingS} ${theme.spacingM}`};
        border-radius: 20rem;
        z-index: 1;
      }

      img {
        max-width: 75%;
        transform: translateY(-${({ theme }) => theme.spacingXXS});
      }
    }
  }

  @media screen and (max-width: 1500px) {
    .intro .image {
      border-bottom-left-radius: 50% 25rem;
      border-bottom-right-radius: 50% 25rem;
    }
  }

  @media screen and (max-width: 1000px) {
    .separator{
      padding-top: 0;
      padding-bottom: 0;
      display: block;
      hr{
        display: none;
      }
    }
    .intro {
      flex-direction: column-reverse;

      .image {
        min-width: unset;
        width: 90%;
        height: 50vh;
        margin: 0 auto;
        transform: none;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
      }

      .content {
        margin-top: ${({ theme }) => theme.spacingM};
        text-align: center;

        .title {
          font-size: 5rem;
          line-height: 5rem;
          max-width: 60rem;
        }

        .text {
          margin: ${({ theme }) => `${theme.spacingM} auto 0 auto`};
        }
      }

      .arrow {
        display: none;
      }
    }

    .separator-image {
      height: 30vh;
      margin-top: ${({ theme }) => theme.spacingL};
    }

    .offices {
      .image-slider {
        width: 85%;
      }

      .sterilization-room {
        margin-top: ${({ theme }) => theme.spacingL};
        img {
          max-width: 85%;
        }
      }
    }
  }

  @media screen and (max-width: 550px) {
    .intro {
      .image {
        height: 35vh;
      }
    }

    .offices {
      .text {
        max-width: 90%;
      }

      .images-container .image-slider {
        transform: translateY(-${({ theme }) => theme.spacingXS});

        span {
          font-size: 6.5rem;
        }
      }
    }
  }

  @media screen and (max-width: 450px) {
    .offices .images-container {
      padding-bottom: ${({ theme }) => theme.spacingL};
    }
  }

  @media screen and (max-width: 375px) {
    .intro .image {
      height: 30vh;
    }

    .offices {
      .image-slider {
        width: 80%;
      }

      .sterilization-room {
        .title {
          font-size: 2.8rem;
          padding: ${({ theme }) => `${theme.spacingXS} ${theme.spacingS}`};
        }

        img {
          max-width: 80%;
        }
      }
    }
  }
`

export const Styled = {
  Container,
}
